import * as React from "react";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import { Accordion } from '@mantine/core';
import ContactModal from '../components/contactModal';

const bgBlueGreen = `url(/img/bg-hero.png)`;


const ProductPage = () => {
  
    return (
      <Layout scrollNavbar={true}>
      <main className="pt-20 bg-center">
        <section className=" bg-cover  mb-4 bg-white py-16" > 
          <div className="grid grid-cols-2 md:grid-cols-12 gap-6 px-3  max-w-6xl mx-auto">
            <div className="text-gray-800 col-span-7 flex items-center" >
              <div className="text-center md:text-left">
                <div className="uppercase leading-normal tracking-widest font-semibold">Documentation & Workflow </div>
                <h1 className="text-4xl lg:text-7xl mt-2 font-bold">Unlock the Key to Modern IT Ops Efficiency</h1>
                <p className="text-2xl mt-6">From Runbook Automation to Incident Response, manage it all with one flexible platform.</p>
              </div>
              
            </div>
            <div className="col-span-4">
              <img src="/img/robo-help.svg" alt="hero" className="w-48 mx-auto lg:w-full" />
            </div>
          </div>
          <div>

          </div>
         
        </section>
        <section className="bg-pink-100">
          <div className="gap-8 items-center py-8 px-4 mx-auto max-w-6xl xl:gap-16 md:grid md:grid-cols-2 lg:px-6 pb-12 ">
              <img className="w-full shadow-lg rounded-lg" src="/img/mockup-2.png" alt="dashboard image"/>
      
              <div className="">
                  <h2 className="mb-4 text-4xl tracking-tight font-semibold ">With Documentation at it's Core, Everyone Is on the Same Page</h2>
                  <p className="mb-6 font-light text-gray-800 md:text-lg pb-4">With Comprehensive documentation, you create a shared understanding and promote effective collaboration among team members and stakeholders.</p>
                  <ContactModal btnSize="md" leadTag="Contact Modal" />
              </div>
          </div>
        </section>
        <section className="bg-white rounded-3xl">
            <div className="py-8 sm:py-16 px-4 lg:px-6 mx-auto max-w-6xl ">
                <div className="max-w-screen-md mb-8 lg:mb-16">
                    <h2 className="mb-4 text-4xl tracking-tight font-semibold text-gray-900">A Center Console to Manage It Operations  </h2>
                    <p className="text-gray-500 sm:text-xl ">Operations is constantly changing and your teams processes should adjust accordingly. Using the Agile Methodology for Automation allows teams to progressively evolve their automation by starting with documentation as a base.</p>
                </div>
                <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
                    <div>
                        <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-blue-100 lg:h-12 lg:w-12 ">
                        <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2 20V18C2 16.9391 2.42143 15.9217 3.17157 15.1716C3.92172 14.4214 4.93913 14 6 14H10C11.0609 14 12.0783 14.4214 12.8284 15.1716C13.5786 15.9217 14 16.9391 14 18V20M4 6C4 7.06087 4.42143 8.07828 5.17157 8.82843C5.92172 9.57857 6.93913 10 8 10C9.06087 10 10.0783 9.57857 10.8284 8.82843C11.5786 8.07828 12 7.06087 12 6C12 4.93913 11.5786 3.92172 10.8284 3.17157C10.0783 2.42143 9.06087 2 8 2C6.93913 2 5.92172 2.42143 5.17157 3.17157C4.42143 3.92172 4 4.93913 4 6Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>


                        </div>
                        <h3 className="mb-2 text-xl font-semibold ">Self Service Automation</h3>
                        <p className="text-gray-500 dark:text-gray-400">Pass the torch from subject matter experts, and enable team members seamless task execution with a shared level of proficiency.</p>
                    </div>
                    <div>
                        <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-blue-100 lg:h-12 lg:w-12 ">
                        <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8 10C10 7.04 8 3 7 2C7 5.038 5.227 6.741 4 8C2.774 9.26 2 11.24 2 13C2 14.5913 2.63214 16.1174 3.75736 17.2426C4.88258 18.3679 6.4087 19 8 19C9.5913 19 11.1174 18.3679 12.2426 17.2426C13.3679 16.1174 14 14.5913 14 13C14 11.468 12.944 9.06 12 8C10.214 11 9.209 11 8 10Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>

                        </div>
                        <h3 className="mb-2 text-xl font-semibold ">Incident Response</h3>
                        <p className="text-gray-500 dark:text-gray-400">Plan it, create it, launch it. Collaborate seamlessly with all  the organization and hit your marketing goals every month with our marketing plan.</p>
                    </div>
                    <div>
                        <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-blue-100 lg:h-12 lg:w-12 ">
                        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.5 2.5L3 4L5.5 1.5M1.5 8.5L3 10L5.5 7.5M1.5 14.5L3 16L5.5 13.5M9 3H18M9 9H18M9 15H18" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>


                        </div>
                        <h3 className="mb-2 text-xl font-semibold ">Standard Operating Procedures</h3>
                        <p className="text-gray-500 dark:text-gray-400">Plan it, create it, launch it. Collaborate seamlessly with all  the organization and hit your marketing goals every month with our marketing plan.</p>
                    </div>
                    <div>
                        <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-blue-100 lg:h-12 lg:w-12 ">
                        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3 13C3 13.5304 3.21071 14.0391 3.58579 14.4142C3.96086 14.7893 4.46957 15 5 15C5.53043 15 6.03914 14.7893 6.41421 14.4142C6.78929 14.0391 7 13.5304 7 13M3 13C3 12.4696 3.21071 11.9609 3.58579 11.5858C3.96086 11.2107 4.46957 11 5 11C5.53043 11 6.03914 11.2107 6.41421 11.5858C6.78929 11.9609 7 12.4696 7 13M3 13H1V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H11V13M7 13H13M13 13C13 13.5304 13.2107 14.0391 13.5858 14.4142C13.9609 14.7893 14.4696 15 15 15C15.5304 15 16.0391 14.7893 16.4142 14.4142C16.7893 14.0391 17 13.5304 17 13M13 13C13 12.4696 13.2107 11.9609 13.5858 11.5858C13.9609 11.2107 14.4696 11 15 11C15.5304 11 16.0391 11.2107 16.4142 11.5858C16.7893 11.9609 17 12.4696 17 13M17 13H19V7M19 7H11M19 7L16 2H11M4 6H8M6 4V8" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>


                        </div>
                        <h3 className="mb-2 text-xl font-semibold ">Disaster Recovery</h3>
                        <p className="text-gray-500 dark:text-gray-400">Plan it, create it, launch it. Collaborate seamlessly with all  the organization and hit your marketing goals every month with our marketing plan.</p>
                    </div>
                    <div>
                        <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-blue-100 lg:h-12 lg:w-12 ">
                        <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M18.9998 14.9994C19.928 14.9994 20.8183 14.6307 21.4746 13.9743C22.131 13.3179 22.4998 12.4277 22.4998 11.4994C22.4998 10.5712 22.131 9.68093 21.4746 9.02456C20.8183 8.36818 19.928 7.99943 18.9998 7.99943H17.9998C18.3968 6.23143 17.7148 4.40643 16.2118 3.21243C14.7088 2.01943 12.6118 1.63743 10.7118 2.21243C8.81176 2.78743 7.39676 4.23143 6.99976 5.99943C4.80076 5.91143 2.84476 7.32543 2.33376 9.37243C1.82176 11.4194 2.89776 13.5264 4.89976 14.3994M10 12V10C10 9.46957 10.2107 8.96086 10.5858 8.58579C10.9609 8.21071 11.4696 8 12 8C12.5304 8 13.0391 8.21071 13.4142 8.58579C13.7893 8.96086 14 9.46957 14 10V12M8 13C8 12.7348 8.10536 12.4804 8.29289 12.2929C8.48043 12.1054 8.73478 12 9 12H15C15.2652 12 15.5196 12.1054 15.7071 12.2929C15.8946 12.4804 16 12.7348 16 13V16C16 16.2652 15.8946 16.5196 15.7071 16.7071C15.5196 16.8946 15.2652 17 15 17H9C8.73478 17 8.48043 16.8946 8.29289 16.7071C8.10536 16.5196 8 16.2652 8 16V13Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>

                        </div>
                        <h3 className="mb-2 text-xl font-semibold ">Access Controls & Permissions</h3>
                        <p className="text-gray-500 dark:text-gray-400">Plan it, create it, launch it. Collaborate seamlessly with all  the organization and hit your marketing goals every month with our marketing plan.</p>
                    </div>
                    <div>
                        <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-blue-100 lg:h-12 lg:w-12 ">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11 11C10.7348 11 10.4804 10.8946 10.2929 10.7071C10.1054 10.5196 10 10.2652 10 10C10 9.73478 10.1054 9.48043 10.2929 9.29289C10.4804 9.10536 10.7348 9 11 9C11.2652 9 11.5196 9.10536 11.7071 9.29289C11.8946 9.48043 12 9.73478 12 10C12 10.2652 11.8946 10.5196 11.7071 10.7071C11.5196 10.8946 11.2652 11 11 11ZM11 11V13.5M10.9998 2C13.3357 4.06658 16.3843 5.14257 19.4998 5C19.9534 6.54302 20.0922 8.16147 19.908 9.75918C19.7237 11.3569 19.2202 12.9013 18.4272 14.3005C17.6343 15.6998 16.5682 16.9254 15.2923 17.9045C14.0164 18.8836 12.5566 19.5962 10.9998 20C9.44305 19.5962 7.98331 18.8836 6.70738 17.9045C5.43144 16.9254 4.36534 15.6998 3.57243 14.3005C2.77952 12.9013 2.27597 11.3569 2.09171 9.75918C1.90745 8.16147 2.04624 6.54302 2.49983 5C5.61536 5.14257 8.664 4.06658 10.9998 2Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        </div>
                        <h3 className="mb-2 text-xl font-semibold ">Security & Compliance</h3>
                        <p className="text-gray-500 dark:text-gray-400">Plan it, create it, launch it. Collaborate seamlessly with all  the organization and hit your marketing goals every month with our marketing plan.</p>
                    </div>
                  
                </div>
            </div>
          </section>
          <section className="mt-12 ">
            <div className="py-8 sm:py-16 px-4 lg:px-6 grid grid-cols-1 md:grid-cols-12 gap-8 max-w-6xl mx-auto justify-between">
            
              <div className="md:col-span-5">
                <h2 className="mb-4 text-4xl tracking-tight font-semibold text-gray-900  ">Designed For Agile IT Ops Teams</h2>
                <div className="">
                  <p className="text-gray-500 text-lg ">Operations are in a constant state of flux, demanding agile responses from your teams. Embracing the Agile Methodology for Automation allows your teams to seamlessly adapt their automation practices.  </p>
                
                  <p  className="mt-3 text-gray-500 text-lg "> Start strong with documentation and empower your teams to navigate changes effortlessly, ensuring their processes remain efficient and aligned with evolving operational needs.</p>
                  <p className="mt-3 text-gray-500 text-lg ">
                  By leveraging comprehensive documentation as a foundation, your teams can progressively evolve their automation capabilities. 
                  </p>
                </div>
              </div>
              <div className="md:col-span-7 space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="flex justify-start items-center bg-indigo-50 rounded-2xl p-6 w-full">
                    <img src="/img/lvl-1.svg" className="h-28" alt="" />
                    <div className="text-xl font-semibold ml-3">Documentation</div>
                  </div>
                  <div className="flex justify-start items-center bg-indigo-50 rounded-2xl p-6 w-full">
                    <img src="/img/lvl-2.svg" className="h-28" alt="" />
                    <div className="text-xl font-semibold ml-3">Manual Checks</div>
                  </div>
                  <div className="flex justify-start items-center bg-indigo-50 rounded-2xl p-6 w-full">
                    <img src="/img/lvl-3.svg" className="h-28" alt="" />
                    <div className="text-xl font-semibold ml-3">Manual Steps & Auto Verify</div>
                  </div>
                  <div className="flex justify-start items-center bg-indigo-50 rounded-2xl p-6 w-full">
                    <img src="/img/lvl-4.svg" className="h-28" alt="" />
                    <div className="text-xl font-semibold ml-3">Hybrid Steps & Auto Verify</div>
                  </div>
                  <div className="flex justify-start items-center bg-indigo-50 rounded-2xl p-6 w-full">
                    <img src="/img/lvl-5.svg" className="h-28" alt="" />
                    <div className="text-xl font-semibold ml-3">Fully Automated</div>
                  </div>
                </div>
                
             
              </div>
              
            </div>
           
          </section>



      </main>

      </Layout>
    
    );
};

export default ProductPage;
